<template>
  <!-- 企业会计制度 利润表-->
  <div class="swbb_table_width">
    <div class="big_box4">
      <div class="top_title3">
        <h4>{{title}}</h4>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>{{durationOfTaxTime}}</span></h6>
          <h6 class="center" v-if="title == '利润表(季报)'"><span>{{periodLrb}}</span></h6>
          <h6 class="center" v-else-if="title == '利润表(月报)'"><span>{{periodLrb2}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:45%;">项目</td>
          <td class="center" style="width:5%;">行次</td>
          <td class="center" style="width:25%;">本年累计金额</td>
          <td class="center" style="width:25%;" v-if="title == '利润表(季报)'">本期金额</td>
          <td class="center" style="width:25%;" v-else-if="title == '利润表(月报)'">本月金额</td>
        </tr>
      </table>
      <!-- <div class="fixed_box_table" :style="{height:contentStyleObj}"> -->
        <el-scrollbar :style="{height:contentStyleObj}">
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight bg_color" style="width:45%;">一、主营业务收入</td>
            <td class="center bg_color" style="width:5%;">1</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[0].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：主营业务成本</td>
            <td class="center bg_color">2</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[1].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">主营业务税⾦及附加</td>
            <td class="center bg_color">3</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[2].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">⼆、主营业务利润（亏损以“－”号填列）(1-2-3)</td>
            <td class="center bg_color">4</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[3].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">加：其他业务利润（亏损以“－”号填列</td>
            <td class="center bg_color">5</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[4].periodTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_k bg_color">减：营业费⽤</td>
            <td class="center bg_color">6</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[5].periodTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_b bg_color">管理费⽤</td>
            <td class="center bg_color">7</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[6].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">财务费⽤</td>
            <td class="center bg_color">8</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[7].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">三、营业利润（亏损以“－”号填列）(4+5-6-7-8)</td>
            <td class="center bg_color">9</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[8].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">加：投资收益（损失以“－”号填列）</td>
            <td class="center bg_color">10</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[9].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">补贴收⼊</td>
            <td class="center bg_color">11</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[10].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">营业外收⼊</td>
            <td class="center bg_color">12</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[11].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：营业外⽀出</td>
            <td class="center bg_color">13</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[12].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">四、利润总额（亏损总额以“－”号填列）(9+10+11+12-13)</td>
            <td class="center bg_color">14</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[13].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：所得税</td>
            <td class="center bg_color">15</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[14].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">五、净利润（净亏损以“－”号填列）(14-15)</td>
            <td class="center bg_color">16</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[15].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="center weight bg_color" colspan="2">项目</td>
            <td class="center weight bg_color">本年累计数</td>
            <td class="center weight bg_color">上年实际数</td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">1．出售、处置部⻔或被投资单位所得收益</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[16].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">2．⾃然灾害发⽣的损失</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[17].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">3．会计政策变更增加（或减少）利润总额</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[18].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">4、会计估计变更增加（或减少）利润总额</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[19].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">5、债务重组损失</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[20].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td colspan="2" class="bg_color">6、其他</td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td  :disabled="false" v-model:amount="attachArr[21].periodTotal" @change="changeList()"></qzf-td>
          </tr>
        </table>
       </el-scrollbar>
      <!-- </div> -->
    </div>
    <div class="save_box" v-if="this.typeName != '历史数据' && $buttonStatus('kjbb_bc')&& (this.startAccountPeriod == ''?( this.currentPeriod!= ''?true:(this.currentPeriod == this.startAccountPeriod?true:false)):(currentPeriod != ''?(this.currentPeriod == this.startAccountPeriod?true:false):(this.currentPeriod == ''?true:false)))">
      <el-button @click="saveLrb" type="primary" size="small"  v-if="this.startAccountPeriod">
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 13px;"></i>
        <span  > 保存</span>
      </el-button>
      <qzf-button @success="saveLrb" type="primary" size="small"  v-else>
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 13px;"></i>
        <span  > 保存</span>
      </qzf-button>
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
import { hisLrbInfo, hisLrbMonthInfo } from '@/api/old.js'

export default {
  name: 'zcfzTable',
  components: { },
  props: {
    title: String,
    typeName: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      attachArr: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      periodLrb: '',
      periodLrb2: '',
      type:0,
      comName:'',
      taxNo:'',
      durationOfTaxTime:'',
      currentPeriod:this.$store.getters["user/comInfo"].period,
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(304)
  },
  methods: {
    changeList(){
      //主营业务利润
      this.attachArr[3].yearTotal = ( this.attachArr[0].yearTotal - 
                                      this.attachArr[1].yearTotal -
                                      this.attachArr[2].yearTotal ).toFixed(2)*1
      this.attachArr[3].periodTotal = ( this.attachArr[0].periodTotal - 
                                        this.attachArr[1].periodTotal - 
                                        this.attachArr[2].periodTotal ).toFixed(2)*1
      //营业利润
      this.attachArr[8].yearTotal = (  this.attachArr[3].yearTotal +
                                       this.attachArr[4].yearTotal -
                                       this.attachArr[5].yearTotal -
                                       this.attachArr[6].yearTotal -
                                       this.attachArr[7].yearTotal).toFixed(2)*1
      this.attachArr[8].periodTotal = ( this.attachArr[3].periodTotal +
                                        this.attachArr[4].periodTotal -
                                        this.attachArr[5].periodTotal -
                                        this.attachArr[6].periodTotal -
                                        this.attachArr[7].periodTotal).toFixed(2)*1
       //利润总额
      this.attachArr[13].yearTotal = (this.attachArr[8].yearTotal +
                                      this.attachArr[9].yearTotal +
                                      this.attachArr[10].yearTotal +
                                      this.attachArr[11].yearTotal -
                                      this.attachArr[12].yearTotal ).toFixed(2)*1
      this.attachArr[13].periodTotal = (this.attachArr[8].periodTotal +
                                        this.attachArr[9].periodTotal +
                                        this.attachArr[10].periodTotal +
                                        this.attachArr[11].periodTotal -
                                        this.attachArr[12].periodTotal ).toFixed(2)*1
      // 净利润
      this.attachArr[15].yearTotal = ( this.attachArr[13].yearTotal - 
                                      this.attachArr[14].yearTotal ).toFixed(2)*1
      this.attachArr[15].periodTotal = ( this.attachArr[13].periodTotal - 
                                        this.attachArr[14].periodTotal ).toFixed(2)*1
    },
    getList(period){
      if(this.title == '利润表(季报)'){
        if (this.typeName == '历史数据') {
          let periodZC
          if (period) {
            periodZC = period
          } else {
            periodZC = this.$store.getters['user/comInfo'].period;
          }
          hisLrbInfo({ period: periodZC }).then(res => {
            if (res.data.data.info) {
              this.attachArr = res.data.data.info
            }
          })
        } else if (this.typeName == '会计报表') {
          if(this.startAccountPeriod){
            let param = {
              tableName: 'kj_lrb',
              period:this.startAccountPeriod,
              comId:this.comId
            }
            if(this.source){
              param.method = this.$store.getters["commons/params"].initMethod
            }
            gsInfo(param).then(res=>{
              this.durationOfTaxTime = this.startAccountPeriod
              this.comName = res.data.data.name
              this.taxNo = res.data.data.taxNo
              if(res.data.data.info && res.data.data.info.length == 22){
                this.attachArr = res.data.data.info
              }else{
                this.$notify({
                  title: '警告',
                  message: '数据校验有误，检测到可能更改过会计准则，请查验初期会计准则是否一致',
                  duration:0,
                  type: 'warning',
                });
              }
            })
          }else{
            gsInfo({tableName: 'kj_lrb'}).then(res=>{
              if(res.data.data.info){
                this.attachArr = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }
        }
      }else if(this.title == '利润表(月报)'){
        if (this.typeName == '历史数据') {
          let periodZC
          if (period) {
            periodZC = period
          } else {
            periodZC = this.$store.getters['user/comInfo'].period;
          }
          hisLrbMonthInfo({ period: periodZC }).then(res => {
            if (res.data.data.info) {
              this.attachArr = res.data.data.info
            }
          })
        } else if (this.typeName == '会计报表') {
          if(this.startAccountPeriod){
            let param = {
              tableName: 'kj_lrb',
              period:this.startAccountPeriod,
              comId:this.comId,
              itemName:'month',
            }
            gsInfo(param).then(res=>{
              if(res.data.data.info){
                this.attachArr = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }else{
            gsInfo({tableName: 'kj_lrb',itemName:'month'}).then(res=>{
              if(res.data.data.info){
                this.attachArr = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }
        }
      }
    },
    saveLrb(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'kj_lrb',
          items:this.attachArr,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        let paramMon = {
          tableName:'kj_lrb',
          items:this.attachArr,
          itemName:'month',
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        if(this.title == '利润表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '利润表(月报)'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }else{
        let param = {
          tableName:'kj_lrb',
          items:this.attachArr
        }
        let paramMon = {
          tableName:'kj_lrb',
          items:this.attachArr,
          itemName:'month'
        }
        if(this.title == '利润表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '利润表(月报)'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  background-color: #fff;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #000;
    font-size: 13px;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}

.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
.bg_color{
  background-color: #d6e2ea;
}
</style>

<template>
  <!-- 资产负债表(适用已执行新金融准则的一般企业)-->
  <div v-loading="loading" class="swbb_table_width">
    <div class="big_box4">
      <div class="top_title3">
        <h4>资产负债表(适用已执行新金融准则的一般企业)</h4>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>{{durationOfTaxTime}}</span></h6>
          <h6 class="right">
            <p>单位：元</p> 
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:21%;">资产</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:12%;">期末余额</td>
          <td class="center" style="width:12%;">年初余额</td>
          <td class="center" style="width:21%;">负债及所有者权益（或股东权益）</td>
          <td class="center" style="width:4%;">行次</td>
          <td class="center" style="width:12%;">期末余额</td>
          <td class="center" style="width:12%;">年初余额</td>
        </tr>
      </table>
      <!-- <div class="fixed_box_table" :style="{height:contentStyleObj}"> -->
        <el-scrollbar :style="{height:contentStyleObj}">
        <table class="content content_body" cellspacing="0">
          <tr>
            <td style="width:21%;" class="bg_color">流动资产：</td>
            <td class="center bg_color" style="width:4%;"></td>
            <td style="width:12%;"><span></span></td>
            <td style="width:12%;"><span></span></td>
            <td style="width:21%;" class="bg_color">流动负债：</td>
            <td style="width:4%;" class="center bg_color"></td>
            <td style="width:12%;"><span></span></td>
            <td style="width:12%;"><span></span></td>
          </tr>

          <tr>
            <td class="left_k bg_color">货币资金</td>
            <td class="center bg_color">1</td>
            <qzf-td :disabled="false" v-model:amount="list[0].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[0].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">短期借款</td>
            <td class="center bg_color">40</td>
            <qzf-td :disabled="false" v-model:amount="list[0].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[0].ncyeQy" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_k bg_color">交易性金融资产</td>
            <td class="center bg_color">2</td>
            <qzf-td :disabled="false" v-model:amount="list[1].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[1].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">交易性金融负债</td>
            <td class="center bg_color">41</td>
            <qzf-td :disabled="false" v-model:amount="list[1].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[1].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">衍生金融资产</td>
            <td class="center bg_color">3</td>
            <qzf-td :disabled="false" v-model:amount="list[2].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[2].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">衍生金融负债</td>
            <td class="center bg_color">42</td>
            <qzf-td :disabled="false" v-model:amount="list[2].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[2].ncyeQy" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_k bg_color">应收票据</td>
            <td class="center bg_color">4</td>
            <qzf-td :disabled="false" v-model:amount="list[3].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[3].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">应付票据</td>
            <td class="center bg_color">43</td>
            <qzf-td :disabled="false" v-model:amount="list[3].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[3].ncyeQy" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_k bg_color">应收账款</td>
            <td class="center bg_color">5</td>
            <qzf-td :disabled="false" v-model:amount="list[4].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[4].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">应付账款</td>
            <td class="center bg_color">44</td>
            <qzf-td :disabled="false" v-model:amount="list[4].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[4].ncyeQy" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_k bg_color">应收款项融资</td>
            <td class="center bg_color">6</td>
            <qzf-td :disabled="false" v-model:amount="list[5].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[5].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">预收款项</td>
            <td class="center bg_color">45</td>
            <qzf-td :disabled="false" v-model:amount="list[5].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[5].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">预付款项</td>
            <td class="center bg_color">7</td>
            <qzf-td :disabled="false" v-model:amount="list[6].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[6].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">合同负债</td>
            <td class="center bg_color">46</td>
            <qzf-td :disabled="false" v-model:amount="list[6].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[6].ncyeQy" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_k bg_color">其他应收款</td>
            <td class="center bg_color">8</td>
            <qzf-td :disabled="false" v-model:amount="list[7].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[7].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">应付职工薪酬</td>
            <td class="center bg_color">47</td>
            <qzf-td :disabled="false" v-model:amount="list[7].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[7].ncyeQy" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_k bg_color">存货</td>
            <td class="center bg_color">9</td>
            <qzf-td :disabled="false" v-model:amount="list[8].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[8].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">应交税费</td>
            <td class="center bg_color">48</td>
            <qzf-td :disabled="false" v-model:amount="list[8].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[8].ncyeQy" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_k bg_color">合同资产</td>
            <td class="center bg_color">10</td>
            <qzf-td :disabled="false" v-model:amount="list[9].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[9].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">其他应付款</td>
            <td class="center bg_color">49</td>
            <qzf-td :disabled="false" v-model:amount="list[9].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[9].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">持有待售资产</td>
            <td class="center bg_color">11</td>
            <qzf-td :disabled="false" v-model:amount="list[10].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[10].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">持有待售负债</td>
            <td class="center bg_color">50</td>
            <qzf-td :disabled="false" v-model:amount="list[10].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[10].ncyeQy" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_k bg_color">一年内到期的非流动资产</td>
            <td class="center bg_color">12</td>
            <qzf-td :disabled="false" v-model:amount="list[11].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[11].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">一年内到期的非流动负债</td>
            <td class="center bg_color">51</td>
            <qzf-td :disabled="false" v-model:amount="list[11].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[11].ncyeQy" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="left_k bg_color">其他流动资产</td>
            <td class="center bg_color">13</td>
            <qzf-td :disabled="false" v-model:amount="list[12].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[12].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">其他流动负债</td>
            <td class="center bg_color">52</td>
            <qzf-td :disabled="false" v-model:amount="list[12].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[12].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          
          <tr>
            <td class="center weight bg_color">流动资产合计</td>
            <td class="center bg_color">14</td>
            <qzf-td :disabled="true" v-model:amount="list[13].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[13].ncyeZc" @change="changeList()"></qzf-td>
            <td class="center weight bg_color">流动负债合计</td>
            <td class="center bg_color">53</td>
            <qzf-td :disabled="true" v-model:amount="list[13].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[13].ncyeQy" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="bg_color">非流动资产：</td>
            <td class="center bg_color"></td>
            <td><input type="text"></td>
            <td><input type="text"></td>
            <td class="bg_color">非流动负债：</td>
            <td class="center bg_color"></td>
            <td><input type="text"></td>
            <td><input type="text"></td>
          </tr>

          <tr>
            <td class="left_k bg_color">债权投资</td>
            <td class="center bg_color">15</td>
            <qzf-td :disabled="false" v-model:amount="list[14].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[14].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">长期借款</td>
            <td class="center bg_color">54</td>
            <qzf-td :disabled="false" v-model:amount="list[14].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[14].ncyeQy" @change="changeList()"></qzf-td>
          </tr>


          <tr>
            <td class="left_k bg_color">其他债权投资</td>
            <td class="center bg_color">16</td>
            <qzf-td :disabled="false" v-model:amount="list[15].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[15].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">应付债券</td>
            <td class="center bg_color">55</td>
            <qzf-td :disabled="false" v-model:amount="list[15].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[15].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">长期应收款</td>
            <td class="center bg_color">17</td>
            <qzf-td :disabled="false" v-model:amount="list[16].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[16].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">其中：优先股</td>
            <td class="center bg_color">56</td>
            <qzf-td :disabled="false" v-model:amount="list[16].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[16].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">长期股权投资</td>
            <td class="center bg_color">18</td>
            <qzf-td :disabled="false" v-model:amount="list[17].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[17].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_x bg_color">永续债</td>
            <td class="center bg_color">57</td>
            <qzf-td :disabled="false" v-model:amount="list[17].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[17].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">其他权益工具投资</td>
            <td class="center bg_color">19</td>
            <qzf-td :disabled="false" v-model:amount="list[18].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[18].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">租赁负债</td>
            <td class="center bg_color">58</td>
            <qzf-td :disabled="false" v-model:amount="list[18].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[18].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">其他非流动性金融资产</td>
            <td class="center bg_color">20</td>
            <qzf-td :disabled="false" v-model:amount="list[19].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[19].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">长期应付款</td>
            <td class="center bg_color">59</td>
            <qzf-td :disabled="false" v-model:amount="list[19].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[19].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">投资性房地产</td>
            <td class="center bg_color">21</td>
            <qzf-td :disabled="false" v-model:amount="list[20].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[20].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">预计负债</td>
            <td class="center bg_color">60</td>
            <qzf-td :disabled="false" v-model:amount="list[20].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[20].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">固定资产</td>
            <td class="center bg_color">22</td>
            <qzf-td :disabled="false" v-model:amount="list[21].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[21].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">递延收益</td>
            <td class="center bg_color">61</td>
            <qzf-td :disabled="false" v-model:amount="list[21].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[21].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">在建工程</td>
            <td class="center bg_color">23</td>
            <qzf-td :disabled="false" v-model:amount="list[22].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[22].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">递延所得税负债</td>
            <td class="center bg_color">62</td>
            <qzf-td :disabled="false" v-model:amount="list[22].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[22].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">生产性生物资产</td>
            <td class="center bg_color">24</td>
            <qzf-td :disabled="false" v-model:amount="list[23].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[23].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">其他非流动负债</td>
            <td class="center bg_color">63</td>
            <qzf-td :disabled="false" v-model:amount="list[23].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[23].ncyeQy" @change="changeList()"></qzf-td>
          </tr> 

          <tr>
            <td class="left_k bg_color">油气资产</td>
            <td class="center bg_color">25</td>
            <qzf-td :disabled="false" v-model:amount="list[24].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[24].ncyeZc" @change="changeList()"></qzf-td>
            <td class="center weight bg_color">非流动负债合计</td>
            <td class="center bg_color">64</td>
            <qzf-td :disabled="true" v-model:amount="list[24].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[24].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">使用权资产</td>
            <td class="center bg_color">26</td>
            <qzf-td :disabled="false" v-model:amount="list[25].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[25].ncyeZc" @change="changeList()"></qzf-td>
            <td class="center weight bg_color">负债合计</td>
            <td class="center bg_color">65</td>
            <qzf-td :disabled="true" v-model:amount="list[25].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[25].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">无形资产</td>
            <td class="center bg_color">27</td>
            <qzf-td :disabled="false" v-model:amount="list[26].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[26].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">所有者权益（或股东权益）：</td>
            <td class="center bg_color"></td>
            <td><input type="text"></td>
            <td><input type="text"></td>
          </tr>
          <tr>
            <td class="left_k bg_color">开发支出</td>
            <td class="center bg_color">28</td>
            <qzf-td :disabled="false" v-model:amount="list[27].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[27].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">实收资本（或股本）	</td>
            <td class="center bg_color">66</td>
            <qzf-td :disabled="false" v-model:amount="list[27].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[27].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">商誉</td>
            <td class="center bg_color">29</td>
            <qzf-td :disabled="false" v-model:amount="list[28].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[28].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">其他权益工具</td>
            <td class="center bg_color">67</td>
            <qzf-td :disabled="false" v-model:amount="list[28].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[28].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">长期待摊费用</td>
            <td class="center bg_color">30</td>
            <qzf-td :disabled="false" v-model:amount="list[29].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[29].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">其中：优先股</td>
            <td class="center bg_color">68</td>
            <qzf-td :disabled="false" v-model:amount="list[29].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[29].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">递延所得税资产</td>
            <td class="center bg_color">31</td>
            <qzf-td :disabled="false" v-model:amount="list[30].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[30].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_x bg_color">永续债</td>
            <td class="center bg_color">69</td>
            <qzf-td :disabled="false" v-model:amount="list[30].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[30].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">其他非流动资产</td>
            <td class="center bg_color">32</td>
            <qzf-td :disabled="false" v-model:amount="list[31].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[31].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">资本公积</td>
            <td class="center bg_color">70</td>
            <qzf-td :disabled="false" v-model:amount="list[31].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[31].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="center weight bg_color">非流动资产合计</td>
            <td class="center bg_color">33</td>
            <qzf-td :disabled="true" v-model:amount="list[32].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[32].ncyeZc" @change="changeList()"></qzf-td>
            <td class="left_k bg_color">减：库存股</td>
            <td class="center bg_color">71</td>
            <qzf-td :disabled="false" v-model:amount="list[32].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[32].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color"></td>
            <td class="center bg_color">34</td>
            <td></td>
            <td></td>
            <td class="left_k bg_color">其他综合收益</td>
            <td class="center bg_color">72</td>
            <qzf-td :disabled="false" v-model:amount="list[33].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[33].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color"></td>
            <td class="center bg_color">35</td>
            <td></td>
            <td></td>
            <td class="left_k bg_color">专项储备</td>
            <td class="center bg_color">73</td>
            <qzf-td :disabled="false" v-model:amount="list[34].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[34].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color"></td>
            <td class="center bg_color">36</td>
            <td></td>
            <td></td>
            <td class="left_k bg_color">盈余公积</td>
            <td class="center bg_color">74</td>
            <qzf-td :disabled="false" v-model:amount="list[35].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[35].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          
          <tr>
            <td class="left_k bg_color"></td>
            <td class="center bg_color">37</td>
            <td></td>
            <td></td>
            <td class="left_k bg_color">未分配利润</td>
            <td class="center bg_color">75</td>
            <qzf-td :disabled="false" v-model:amount="list[36].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[36].ncyeQy" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color"></td>
            <td class="center bg_color">38</td>
            <td></td>
            <td></td>
            <td class="center weight bg_color">所有者权益（或股东权益）合计</td>
            <td class="center bg_color">76</td>
            <qzf-td :disabled="true" v-model:amount="list[37].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[37].ncyeQy" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="center weight bg_color">资产总计</td>
            <td class="center bg_color">39</td>
            <qzf-td :disabled="true" v-model:amount="list[38].qmyeZc" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[38].ncyeZc" @change="changeList()"></qzf-td>
            <td class="center weight bg_color">负债和所有者权益（或股东权益）总计</td>
            <td class="center bg_color">77</td>
            <qzf-td :disabled="true" v-model:amount="list[38].qmyeQy" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[38].ncyeQy" @change="changeList()"></qzf-td>
          </tr>

        </table>
       </el-scrollbar>
      <!-- </div> -->

    </div>
    <div class="save_box" v-if="this.typeName != '历史数据' && $buttonStatus('kjbb_bc') && (this.startAccountPeriod == ''?( this.currentPeriod!= ''?true:(this.currentPeriod == this.startAccountPeriod?true:false)):(currentPeriod != ''?(this.currentPeriod == this.startAccountPeriod?true:false):(this.currentPeriod == ''?true:false)))">
      <el-button @click="saveZcfz" type="primary" size="small"  v-if="this.startAccountPeriod">
          <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 13px;"></i>
        <span  > 保存</span>
      </el-button>
      <qzf-button @success="saveZcfz" type="primary" size="small"  v-else>
          <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 13px;"></i>
        <span  > 保存</span>
      </qzf-button>
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
import { hisZcfzInfo } from '@/api/old.js'

export default {
  name: 'zcfzTable4',
  components: { },
  props: {
    typeName: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      loading: false,
      comName:'',
      durationOfTaxTime:'',
      taxNo:'',
      list:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      currentPeriod:this.$store.getters["user/comInfo"].period,

    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(304)
    this.getList()
  },
  methods: {
     changeList(){
       //流动资产合计
      this.list[13].qmyeZc = (this.list[0].qmyeZc  + 
                              this.list[1].qmyeZc  + 
                              this.list[2].qmyeZc  + 
                              this.list[3].qmyeZc  + 
                              this.list[4].qmyeZc  + 
                              this.list[5].qmyeZc  + 
                              this.list[6].qmyeZc  + 
                              this.list[7].qmyeZc  +
                              this.list[8].qmyeZc  +
                              this.list[9].qmyeZc  +
                              this.list[10].qmyeZc +
                              this.list[11].qmyeZc +
                              this.list[12].qmyeZc ).toFixed(2)*1
      this.list[13].ncyeZc = (this.list[0].ncyeZc + 
                              this.list[1].ncyeZc  + 
                              this.list[2].ncyeZc  + 
                              this.list[3].ncyeZc  + 
                              this.list[4].ncyeZc  + 
                              this.list[5].ncyeZc  + 
                              this.list[6].ncyeZc  + 
                              this.list[7].ncyeZc  +
                              this.list[8].ncyeZc  +
                              this.list[9].ncyeZc  +
                              this.list[10].ncyeZc +
                              this.list[11].ncyeZc +
                              this.list[12].ncyeZc ).toFixed(2)*1
       //非流动资产合计
      this.list[32].qmyeZc = (this.list[14].qmyeZc + 
                              this.list[15].qmyeZc +
                              this.list[16].qmyeZc + 
                              this.list[17].qmyeZc +
                              this.list[18].qmyeZc +
                              this.list[19].qmyeZc + 
                              this.list[20].qmyeZc + 
                              this.list[21].qmyeZc + 
                              this.list[22].qmyeZc + 
                              this.list[23].qmyeZc + 
                              this.list[24].qmyeZc + 
                              this.list[25].qmyeZc +
                              this.list[26].qmyeZc +
                              this.list[27].qmyeZc +
                              this.list[28].qmyeZc +
                              this.list[29].qmyeZc +
                              this.list[30].qmyeZc +
                              this.list[31].qmyeZc ).toFixed(2)*1
      this.list[32].ncyeZc = (this.list[14].ncyeZc + 
                              this.list[15].ncyeZc +
                              this.list[16].ncyeZc + 
                              this.list[17].ncyeZc +
                              this.list[18].ncyeZc +
                              this.list[19].ncyeZc + 
                              this.list[20].ncyeZc + 
                              this.list[21].ncyeZc + 
                              this.list[22].ncyeZc + 
                              this.list[23].ncyeZc + 
                              this.list[24].ncyeZc + 
                              this.list[25].ncyeZc +
                              this.list[26].ncyeZc +
                              this.list[27].ncyeZc +
                              this.list[28].ncyeZc +
                              this.list[29].ncyeZc +
                              this.list[30].ncyeZc +
                              this.list[31].ncyeZc ).toFixed(2)*1
      //资产总计
      this.list[38].qmyeZc = (this.list[13].qmyeZc + 
                              this.list[32].qmyeZc ).toFixed(2)*1
      this.list[38].ncyeZc = (this.list[13].ncyeZc + 
                              this.list[32].ncyeZc ).toFixed(2)*1
      // 流动负债合计
      this.list[13].qmyeQy = (this.list[0].qmyeQy + 
                              this.list[1].qmyeQy + 
                              this.list[2].qmyeQy + 
                              this.list[3].qmyeQy + 
                              this.list[4].qmyeQy + 
                              this.list[5].qmyeQy + 
                              this.list[6].qmyeQy + 
                              this.list[7].qmyeQy +
                              this.list[8].qmyeQy +
                              this.list[9].qmyeQy +
                              this.list[10].qmyeQy + 
                              this.list[11].qmyeQy +
                              this.list[12].qmyeQy ).toFixed(2)*1
      this.list[13].ncyeQy = (this.list[0].ncyeQy + 
                              this.list[1].ncyeQy + 
                              this.list[2].ncyeQy + 
                              this.list[3].ncyeQy + 
                              this.list[4].ncyeQy + 
                              this.list[5].ncyeQy + 
                              this.list[6].ncyeQy + 
                              this.list[7].ncyeQy +
                              this.list[8].ncyeQy +
                              this.list[9].ncyeQy +
                              this.list[10].ncyeQy + 
                              this.list[11].ncyeQy +
                              this.list[12].ncyeQy ).toFixed(2)*1
      // 非流动负债合计
      this.list[24].qmyeQy = (this.list[14].qmyeQy +
                              this.list[15].qmyeQy +
                              this.list[16].qmyeQy + 
                              this.list[17].qmyeQy + 
                              this.list[18].qmyeQy +
                              this.list[19].qmyeQy +
                              this.list[20].qmyeQy +
                              this.list[21].qmyeQy + 
                              this.list[22].qmyeQy + 
                              this.list[23].qmyeQy ).toFixed(2)*1
      this.list[24].ncyeQy = (this.list[14].ncyeQy +
                              this.list[15].ncyeQy +
                              this.list[16].ncyeQy + 
                              this.list[17].ncyeQy + 
                              this.list[18].ncyeQy +
                              this.list[19].ncyeQy +
                              this.list[20].ncyeQy +
                              this.list[21].ncyeQy + 
                              this.list[22].ncyeQy + 
                              this.list[23].ncyeQy ).toFixed(2)*1
      // 负债合计
      this.list[25].qmyeQy = (this.list[13].qmyeQy +
                              this.list[24].qmyeQy ).toFixed(2)*1                        
      this.list[25].ncyeQy = (this.list[13].ncyeQy +
                              this.list[24].ncyeQy ).toFixed(2)*1
      // 所有者权益合计
      this.list[37].qmyeQy = (this.list[27].qmyeQy + 
                              this.list[28].qmyeQy +
                              this.list[31].qmyeQy -
                              this.list[32].qmyeQy +
                              this.list[33].qmyeQy + 
                              this.list[34].qmyeQy +
                              this.list[35].qmyeQy +
                              this.list[36].qmyeQy).toFixed(2)*1
      this.list[37].ncyeQy = (this.list[27].ncyeQy + 
                              this.list[28].ncyeQy +
                              this.list[31].ncyeQy -
                              this.list[32].ncyeQy +
                              this.list[33].ncyeQy +
                              this.list[34].ncyeQy + 
                              this.list[35].ncyeQy +
                              this.list[36].ncyeQy ).toFixed(2)*1
      //负债和所有者权益合计
      this.list[38].qmyeQy = (this.list[25].qmyeQy + 
                              this.list[37].qmyeQy).toFixed(2)*1
      this.list[38].ncyeQy = (this.list[25].ncyeQy + 
                              this.list[37].ncyeQy).toFixed(2)*1
    },
    getList(period){
      if(this.typeName == '历史数据'){
        let periodZC
        if(period){
          periodZC = period
        } else {
          periodZC = this.$store.getters['user/comInfo'].period;
        }
        hisZcfzInfo({period:periodZC}).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.comName = this.$store.getters['user/comInfo'].comName;
            this.taxNo = this.$store.getters['user/comInfo'].taxNo;
            // this.changeList()
            // this.type = res.data.data.type
          }
        })
      }else if(this.typeName == '会计报表') {
        this.loading = true
        this.$parent.$parent.$parent.changeLoading(true)

        if(this.startAccountPeriod){
          let param = {
            tableName: 'kj_zcfz',
            period:this.startAccountPeriod,
            comId:this.comId
          }
          if(this.source){
            param.method = this.$store.getters["commons/params"].initMethod
          }
          gsInfo(param).then(res=>{
            this.loading = false
            this.$parent.$parent.$parent.changeLoading(false)
            this.durationOfTaxTime = this.startAccountPeriod
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            if(res.data.data.info && res.data.data.info.length == 39){
              this.list = res.data.data.info
            }else{
              this.$notify({
                title: '警告',
                message: '数据校验有误，检测到可能更改过会计准则，请查验初期会计准则是否一致',
                duration:0,
                type: 'warning',
              });
            }
          })
        }else{
          gsInfo({tableName: 'kj_zcfz'}).then(res=>{
            this.loading = false
            this.$parent.$parent.$parent.changeLoading(false)

            if(res.data.data.info){
              this.list = res.data.data.info
              this.comName = res.data.data.name
              this.taxNo = res.data.data.taxNo
              this.durationOfTaxTime = res.data.data.time
            }
            if(this.list.length == 39 && (this.list[38].qmyeZc != this.list[38].qmyeQy || this.list[38].ncyeZc != this.list[38].ncyeQy)){
              this.$notify.error({
                title: '提示',
                message: '资产负债不平衡',
                duration: 0,
                type: 'warning',
              });
            }
          })
        }
      }
    },
    saveZcfz(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'kj_zcfz',
          period:this.startAccountPeriod,
          comId:this.comId,
          items:this.list
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'kj_zcfz',
          items:this.list
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  background-color: #fff; 
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #000;
    font-size: 13px;
  }
  input{
    width: 100%;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
.bg_color{
  background-color: #d6e2ea;
}
</style>

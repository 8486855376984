<template>
  <div class="swbb_table_width">
    <div class="big_box4">
      <div class="top_title3">
        <h4>{{title}}</h4>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>{{durationOfTaxTime}}</span></h6>
          <h6 class="center" v-if="title == '盈余及盈余分配表(季报)'"><span>{{periodLrb}}</span></h6>
          <h6 class="center" v-else-if="title == '盈余及盈余分配表'"><span>{{periodLrb2}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:45%;">项目</td>
          <td class="center" style="width:5%;">行次</td>
          <td class="center" style="width:25%;">本期金额</td>
          <td class="center" style="width:25%;">本年累计</td>
        </tr>
      </table>
      <el-scrollbar :style="{height:contentStyleObj}">
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight bg_color" style="width:45%;">一、经营收入：</td>
            <td class="center bg_color" style="width:5%;">1</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="list[0].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="list[0].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">加：投资收益</td>
            <td class="center bg_color">2</td>
            <qzf-td :disabled="false" v-model:amount="list[1].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[1].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">减：经营支出</td>
            <td class="center bg_color">3</td>
            <qzf-td :disabled="false" v-model:amount="list[2].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[2].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">税金及附加</td>
            <td class="center bg_color">4</td>
            <qzf-td :disabled="false" v-model:amount="list[3].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[3].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">管理费用</td>
            <td class="center bg_color">5</td>
            <qzf-td :disabled="false" v-model:amount="list[4].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[4].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">财务费用</td>
            <td class="center bg_color">6</td>
            <qzf-td :disabled="false" v-model:amount="list[5].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[5].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">二、经营收益</td>
            <td class="center bg_color">7</td>
            <qzf-td :disabled="false" v-model:amount="list[6].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[6].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">加：其他收入</td>
            <td class="center bg_color">8</td>
            <qzf-td :disabled="false" v-model:amount="list[7].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[7].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">减：其他支出</td>
            <td class="center bg_color">9</td>
            <qzf-td :disabled="false" v-model:amount="list[8].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[8].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">三、盈余总额</td>
            <td class="center bg_color">10</td>
            <qzf-td :disabled="false" v-model:amount="list[9].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[9].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">减:所得税费用</td>
            <td class="center bg_color">11</td>
            <qzf-td :disabled="false" v-model:amount="list[10].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[10].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">四、本年盈余</td>
            <td class="center bg_color">12</td>
            <qzf-td :disabled="false" v-model:amount="list[11].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[11].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">加：年初未分配盈余</td>
            <td class="center bg_color">13</td>
            <qzf-td :disabled="false" v-model:amount="list[12].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[12].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_k">其他转入</td>
            <td class="center bg_color">14</td>
            <qzf-td :disabled="false" v-model:amount="list[13].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[13].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">减：提取盈余公积</td>
            <td class="center bg_color">15</td>
            <qzf-td :disabled="false" v-model:amount="list[14].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[14].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">五、可分配盈余</td>
            <td class="center bg_color">16</td>
            <qzf-td :disabled="false" v-model:amount="list[15].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[15].amount" @change="changeList()"></qzf-td>
          </tr>
         
          <tr>
            <td class="bg_color">盈余返还</td>
            <td class="center bg_color">17</td>
            <qzf-td :disabled="false" v-model:amount="list[16].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[16].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">剩余盈余分配</td>
            <td class="center bg_color">18</td>
            <qzf-td :disabled="false" v-model:amount="list[17].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[17].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">转为成员出资</td>
            <td class="center bg_color">19</td>
            <qzf-td :disabled="false" v-model:amount="list[18].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[18].amount" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">六、年末未分配盈余</td>
            <td class="center bg_color">20</td>
            <qzf-td :disabled="false" v-model:amount="list[19].amountPeriod" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[19].amount" @change="changeList()"></qzf-td>
          </tr>
        </table>
      </el-scrollbar>
    </div>
    <div class="save_box" v-if="this.typeName != '历史数据'&& $buttonStatus('kjbb_bc')">
      <el-button @click="saveLrb" type="primary" size="small"  v-if="this.startAccountPeriod">
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 13px;"></i>
        <span  > 保存</span>
      </el-button>
      <qzf-button @success="saveLrb" type="primary" size="small"  v-else>
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 13px;"></i>
        <span  > 保存</span>
      </qzf-button>
    </div>
    <!-- 新增弹窗取数来源 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogConversion" title="新增取数来源"  class="button_bg">
      <el-form ref="dataForm" label-position="left" label-width="60px" class="clearfix" style="overflow:hidden">
        <el-button style="margin-bottom:10px;" size="small" @click="addUnits" round>新增取数来源</el-button>
        <div style="margin-bottom:10px;" v-for="(item,index) in qslyList" :key="index">
          <span>科目：</span>
          <subject-list v-model:subjectId="item.subjectId"></subject-list>
          <!-- <el-select filterable v-model="item.code" placeholder="请选择" size="small">
            <el-option v-for="item in subjectList" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select> -->
          <i style="font-size:20px;margin-left:10px;cursor: pointer;" @click="delUnits(index)" class="iconfont icon-shanchu"></i>       
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogConversion = false" size="small">取 消</el-button>
          <el-button type="primary" @click="conversionSave()" size="small">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit ,eaLrbOriginLists ,eaLrbOriginSave} from '@/api/taxCalculation.js'
import { hisLrbInfo,hisLrbMonthInfo } from '@/api/old.js'
export default {
  name: 'lrbTable',
  components: { },
  props: {
    title: String,
    typeName: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      list: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      periodLrb: '',
      periodLrb2: '',
      type:0,
      qslyList: [],
      dialogConversion: false,
      subjectList:[],
      comName:'',
      taxNo:'',
      durationOfTaxTime:'',
      num:0
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(302)
  },
  methods: {
    changeList(){
     
    },
    getList(period){
      if(this.title == '盈余及盈余分配表(季报)'){
        if(this.typeName == '历史数据'){
        let periodZC
        if(period){
          periodZC = period
        } else {
          periodZC = this.$store.getters['user/comInfo'].period;
        }
        hisLrbInfo({period:periodZC}).then(res=>{
          if(res.data.data.info && res.data.data.info.length == 20){
            this.list = res.data.data.info
            // this.type = res.data.data.type
          }else{
            this.$notify({
              title: '警告',
              message: '数据校验有误，请重新取数',
              duration:0,
              type: 'warning',
            });
          }
        })} else if(this.typeName == '会计报表') {
        if(this.startAccountPeriod){
          let param = {
            tableName: 'kj_lrb',
            period:this.startAccountPeriod,
            comId:this.comId
          }
          gsInfo(param).then(res=>{
            this.durationOfTaxTime = this.startAccountPeriod
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            if(res.data.data.info && res.data.data.info.length == 20){
              this.list = res.data.data.info
            }else{
              this.$notify({
                title: '警告',
                message: '数据校验有误，请重新初始化',
                duration:0,
                type: 'warning',
              });
            }
          })
        }else{
          gsInfo({tableName: 'kj_lrb'}).then(res=>{
            if(res.data.data.info && res.data.data.info.length == 20){
              this.list = res.data.data.info
              this.comName = res.data.data.name
              this.taxNo = res.data.data.taxNo
              this.durationOfTaxTime = res.data.data.time
            }else{
              this.$notify({
                title: '警告',
                message: '数据校验有误，请重新取数',
                duration:0,
                type: 'warning',
              });
            }
          })
        }
      }}else if(this.title == '盈余及盈余分配表'){
        if(this.typeName == '历史数据'){
          let periodZC
          if(period){
            periodZC = period
          } else {
            periodZC = this.$store.getters['user/comInfo'].period;
          }
          hisLrbMonthInfo({period:periodZC}).then(res=>{
            if(res.data.data.info && res.data.data.info.length == 20){
              this.list = res.data.data.info
              // this.type = res.data.data.type
            }else{
              this.$notify({
                title: '警告',
                message: '数据校验有误，请重新取数',
                duration:0,
                type: 'warning',
              });
            }
          })
        } else if(this.typeName == '会计报表') {
          if(this.startAccountPeriod){
            let param = {
              tableName: 'kj_lrb',
              period:this.startAccountPeriod,
              comId:this.comId,
            }
            if(this.source){
              param.method = this.$store.getters["commons/params"].initMethod
            }
            gsInfo(param).then(res=>{
              if(res.data.data.info && res.data.data.info.length == 20){
                this.list = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }else{
                this.$notify({
                  title: '警告',
                  message: '数据校验有误，请重新初始化',
                  duration:0,
                  type: 'warning',
                });
              }
            })
          }else{
            gsInfo({tableName: 'kj_lrb'}).then(res=>{
              if(res.data.data.info && res.data.data.info.length == 20){
                this.list = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }else{
                this.$notify({
                  title: '警告',
                  message: '数据校验有误，请重新取数',
                  duration:0,
                  type: 'warning',
                });
              }
            })
          }
        }
      }
    },
    saveLrb(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'kj_lrb',
          items:this.list,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        let paramMon = {
          tableName:'kj_lrb',
          items:this.list,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        if(this.title == '盈余及盈余分配表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '盈余及盈余分配表'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }else{
        let param = {
          tableName:'kj_lrb',
          items:this.list
        }
        let paramMon = {
          tableName:'kj_lrb',
          items:this.list,
        }
        if(this.title == '盈余及盈余分配表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '盈余及盈余分配表'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }
    },
    // 取数来源
    qslyShow(type,num){
      if(this.$checkSettleStatus()) return
      //console.log(num)
      this.qslyList = [] //放到接口
      this.qslxType = type
      this.num = num
      eaLrbOriginLists({name:type}).then(res=>{
        this.qslyList = res.data.data.list
        this.dialogConversion = true
      })
    },
    // 删除取数来源
    delUnits(index){
      this.qslyList.splice(index, 1)
    },
    addUnits(){
      this.qslyList.push({
        subjectId: '',
      })
    },
    // 保存取数来源
    conversionSave(){
      let param = {name:this.qslxType,num:Number(this.num),items:this.qslyList}
      eaLrbOriginSave(param).then(res=>{
        if(res.data.msg != 'success'){
          return
        }
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.dialogConversion = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  background-color: #fff;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #000;
    font-size: 13px;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 3px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
.bg_color{
  background-color: #d6e2ea;
}
</style>

<template>
  <!-- 民办非业务活动表-->
  <div class="swbb_table_width">
    <div class="top_title3">
      <h4>业务活动表（季报）</h4>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center"><span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <span
      style="
        font-size: 13px;
        color: var(--themeColor, #17a2b8);
      "
    >
      <i style="font-size: 13px" class="iconfont icon-gantanhao1"></i>
      <span>温馨提示：会计科目名称若不区分“限定性”或“非限定性”，报表取值统一取到“非限定性”栏次体现</span>
    </span>
    <table class="content content_head" cellspacing="0">
      <tr class="top_bg">
        <td class="center" style="width:24%;" rowspan="2">项目</td>
        <td class="center" style="width:4%;" rowspan="2">行次</td>
        <td class="center" style="width:36%;" colspan="3">本季数</td>
        <td class="center" style="width:36%;" colspan="3">本年累计数</td>
      </tr>
      <tr class="top_bg">
        <td class="center" style="width:12%;">非限定性</td>
        <td class="center" style="width:12%;">限定性</td>
        <td class="center" style="width:12%;">合计</td>
        <td class="center" style="width:12%;">非限定性</td>
        <td class="center" style="width:12%;">限定性</td>
        <td class="center" style="width:12%;">合计</td>
      </tr>
    </table>
    <!-- <div class="fixed_box_table" :style="{height:contentStyleObj}"> -->
    <el-scrollbar :style="{height:contentStyleObj}">   
      <table class="content content_body" cellspacing="0">
        <tr>
          <td class="weight bg_color" style="width:24%;">一、收入</td>
          <td style="width:4%;" class="center bg_color">1</td>
          <td style="width:12%;"></td>
          <td style="width:12%;"></td>
          <td style="width:12%;"></td>
          <td style="width:12%;"></td>
          <td style="width:12%;"></td>
          <td style="width:12%;"></td>
        </tr>

        <tr>
          <td class="left_k bg_color">其中：捐赠收入</td>
          <td class="center bg_color">2</td>
          <qzf-td @change="changeList()" v-model:amount="list[1].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[1].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[1].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[1].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[1].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[1].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="left_k bg_color">会费收入</td>
          <td class="center bg_color">3</td>
          <qzf-td @change="changeList()" v-model:amount="list[2].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[2].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[2].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[2].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[2].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[2].ncyeQy" :disabled="true"></qzf-td>
        </tr>

        <tr>
          <td class="left_k bg_color">提供服务收入</td>
          <td class="center bg_color">4</td>
          <qzf-td @change="changeList()" v-model:amount="list[3].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[3].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[3].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[3].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[3].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[3].ncyeQy" :disabled="true"></qzf-td>
        </tr>

        <tr>
          <td class="left_k bg_color">商品销售收入</td>
          <td class="center bg_color">5</td>
          <qzf-td @change="changeList()" v-model:amount="list[4].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[4].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[4].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[4].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[4].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[4].ncyeQy" :disabled="true"></qzf-td>
        </tr>

        <tr>
          <td class="left_k bg_color">政府补助收入</td>
          <td class="center bg_color">6</td>
          <qzf-td @change="changeList()" v-model:amount="list[5].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[5].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[5].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[5].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[5].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[5].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="left_k bg_color">投资收益</td>
          <td class="center bg_color">7</td>
          <qzf-td @change="changeList()" v-model:amount="list[6].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[6].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[6].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[6].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[6].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[6].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="left_k bg_color">其他收入</td>
          <td class="center bg_color">8</td>
          <qzf-td @change="changeList()" v-model:amount="list[7].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[7].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[7].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[7].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[7].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[7].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="weight bg_color">收入合计</td>
          <td class="center bg_color">9</td>
          <qzf-td @change="changeList()" v-model:amount="list[8].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[8].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[8].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[8].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[8].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[8].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        
        <tr>
          <td class="weight bg_color">二、费用</td>
          <td class="center bg_color">10</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="weight bg_color">（一）业务活动成本</td>
          <td class="center bg_color">11</td>
          <qzf-td @change="changeList()" v-model:amount="list[10].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[10].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[10].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[10].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[10].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[10].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="left_k bg_color">其中：</td>
          <td class="center bg_color">12</td>
          <qzf-td @change="changeList()" v-model:amount="list[11].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[11].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[11].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[11].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[11].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[11].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="left_k bg_color"></td>
          <td class="center bg_color">13</td>
          <qzf-td @change="changeList()" v-model:amount="list[12].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[12].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[12].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[12].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[12].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[12].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="left_k bg_color"></td>
          <td class="center bg_color">14</td>
          <qzf-td @change="changeList()" v-model:amount="list[13].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[13].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[13].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[13].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[13].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[13].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="left_k bg_color"></td>
          <td class="center bg_color">15</td>
          <qzf-td @change="changeList()" v-model:amount="list[14].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[14].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[14].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[14].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[14].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[14].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="left_k bg_color">（二）管理费用</td>
          <td class="center bg_color">16</td>
          <qzf-td @change="changeList()" v-model:amount="list[15].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[15].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[15].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[15].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[15].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[15].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="left_k bg_color">（三）筹资费用</td>
          <td class="center bg_color">17</td>
          <qzf-td @change="changeList()" v-model:amount="list[16].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[16].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[16].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[16].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[16].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[16].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="weight bg_color">（四）其他费用</td>
          <td class="center bg_color">18</td>
          <qzf-td @change="changeList()" v-model:amount="list[17].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[17].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[17].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[17].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[17].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[17].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="weight bg_color">费用合计</td>
          <td class="center bg_color">19</td>
          <qzf-td @change="changeList()" v-model:amount="list[18].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[18].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[18].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[18].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[18].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[18].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="bg_color">三、限定性净资产转为非限定性净资产</td>
          <td class="center bg_color">20</td>
          <qzf-td @change="changeList()" v-model:amount="list[19].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[19].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[19].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[19].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[19].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[19].ncyeQy" :disabled="true"></qzf-td>
        </tr>
        <tr>
          <td class="bg_color">四、净资产变动额（若为净资产减少额，以“-”号填列）</td>
          <td class="center bg_color">21</td>
          <qzf-td @change="changeList()" v-model:amount="list[20].periodFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[20].periodXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[20].qmyeZc" :disabled="true"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[20].yearFxdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[20].yearXdxTotal"></qzf-td>
          <qzf-td @change="changeList()" v-model:amount="list[20].ncyeQy" :disabled="true"></qzf-td>
        </tr>

      </table>
    </el-scrollbar>
    <!-- </div> -->
    <div class="save_box" v-if="this.typeName != '历史数据' && $buttonStatus('kjbb_bc') && (this.startAccountPeriod == ''?( this.currentPeriod!= ''?true:(this.currentPeriod == this.startAccountPeriod?true:false)):(currentPeriod != ''?(this.currentPeriod == this.startAccountPeriod?true:false):(this.currentPeriod == ''?true:false)))">
      <el-button type="primary" size="small" @click="saveLrb"><i class="iconfont icon-baocun"></i>保存</el-button>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
import {  hisLrbInfo } from '@/api/old.js'

export default {
  name: 'finance',
  components: { },
  props: {
    typeName: {
      default:'',
      type:String
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      fullscreenLoading: false,
      durationOfTaxTime: '',
      list:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      contentStyleObj:{}, //高度变化
      currentPeriod:this.$store.getters["user/comInfo"].period,

    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(355)
  },
  methods: {
    changeList(){
      //合计
      this.list[1].qmyeZc = (this.list[1].periodFxdxTotal  + this.list[1].periodXdxTotal ).toFixed(2)*1
      this.list[2].qmyeZc = (this.list[2].periodFxdxTotal  + this.list[2].periodXdxTotal ).toFixed(2)*1
      this.list[3].qmyeZc = (this.list[3].periodFxdxTotal  + this.list[3].periodXdxTotal ).toFixed(2)*1
      this.list[4].qmyeZc = (this.list[4].periodFxdxTotal  + this.list[4].periodXdxTotal ).toFixed(2)*1
      this.list[5].qmyeZc = (this.list[5].periodFxdxTotal  + this.list[5].periodXdxTotal ).toFixed(2)*1
      this.list[6].qmyeZc = (this.list[6].periodFxdxTotal  + this.list[6].periodXdxTotal ).toFixed(2)*1
      this.list[7].qmyeZc = (this.list[7].periodFxdxTotal  + this.list[7].periodXdxTotal ).toFixed(2)*1
      this.list[8].qmyeZc = (this.list[8].periodFxdxTotal  + this.list[8].periodXdxTotal ).toFixed(2)*1
      this.list[9].qmyeZc = (this.list[9].periodFxdxTotal  + this.list[9].periodXdxTotal ).toFixed(2)*1
      this.list[10].qmyeZc = (this.list[10].periodFxdxTotal  + this.list[10].periodXdxTotal ).toFixed(2)*1
      this.list[11].qmyeZc = (this.list[11].periodFxdxTotal  + this.list[11].periodXdxTotal ).toFixed(2)*1
      this.list[12].qmyeZc = (this.list[12].periodFxdxTotal  + this.list[12].periodXdxTotal ).toFixed(2)*1
      this.list[13].qmyeZc = (this.list[13].periodFxdxTotal  + this.list[13].periodXdxTotal ).toFixed(2)*1
      this.list[14].qmyeZc = (this.list[14].periodFxdxTotal  + this.list[14].periodXdxTotal ).toFixed(2)*1
      this.list[15].qmyeZc = (this.list[15].periodFxdxTotal  + this.list[15].periodXdxTotal ).toFixed(2)*1
      this.list[16].qmyeZc = (this.list[16].periodFxdxTotal  + this.list[16].periodXdxTotal ).toFixed(2)*1
      this.list[17].qmyeZc = (this.list[17].periodFxdxTotal  + this.list[17].periodXdxTotal ).toFixed(2)*1
      this.list[18].qmyeZc = (this.list[18].periodFxdxTotal  + this.list[18].periodXdxTotal ).toFixed(2)*1
      this.list[19].qmyeZc = (this.list[19].periodFxdxTotal  + this.list[19].periodXdxTotal ).toFixed(2)*1
      this.list[20].qmyeZc = (this.list[20].periodFxdxTotal  + this.list[20].periodXdxTotal ).toFixed(2)*1

      this.list[1].ncyeQy = (this.list[1].yearFxdxTotal  + this.list[1].yearXdxTotal ).toFixed(2)*1
      this.list[2].ncyeQy = (this.list[2].yearFxdxTotal  + this.list[2].yearXdxTotal ).toFixed(2)*1
      this.list[3].ncyeQy = (this.list[3].yearFxdxTotal  + this.list[3].yearXdxTotal ).toFixed(2)*1
      this.list[4].ncyeQy = (this.list[4].yearFxdxTotal  + this.list[4].yearXdxTotal ).toFixed(2)*1
      this.list[5].ncyeQy = (this.list[5].yearFxdxTotal  + this.list[5].yearXdxTotal ).toFixed(2)*1
      this.list[6].ncyeQy = (this.list[6].yearFxdxTotal  + this.list[6].yearXdxTotal ).toFixed(2)*1
      this.list[7].ncyeQy = (this.list[7].yearFxdxTotal  + this.list[7].yearXdxTotal ).toFixed(2)*1
      this.list[8].ncyeQy = (this.list[8].yearFxdxTotal  + this.list[8].yearXdxTotal ).toFixed(2)*1
      this.list[9].ncyeQy = (this.list[9].yearFxdxTotal  + this.list[9].yearXdxTotal ).toFixed(2)*1
      this.list[10].ncyeQy = (this.list[10].yearFxdxTotal  + this.list[10].yearXdxTotal ).toFixed(2)*1
      this.list[11].ncyeQy = (this.list[11].yearFxdxTotal  + this.list[11].yearXdxTotal ).toFixed(2)*1
      this.list[12].ncyeQy = (this.list[12].yearFxdxTotal  + this.list[12].yearXdxTotal ).toFixed(2)*1
      this.list[13].ncyeQy = (this.list[13].yearFxdxTotal  + this.list[13].yearXdxTotal ).toFixed(2)*1
      this.list[14].ncyeQy = (this.list[14].yearFxdxTotal  + this.list[14].yearXdxTotal ).toFixed(2)*1
      this.list[15].ncyeQy = (this.list[15].yearFxdxTotal  + this.list[15].yearXdxTotal ).toFixed(2)*1
      this.list[16].ncyeQy = (this.list[16].yearFxdxTotal  + this.list[16].yearXdxTotal ).toFixed(2)*1
      this.list[17].ncyeQy = (this.list[17].yearFxdxTotal  + this.list[17].yearXdxTotal ).toFixed(2)*1
      this.list[18].ncyeQy = (this.list[18].yearFxdxTotal  + this.list[18].yearXdxTotal ).toFixed(2)*1
      this.list[19].ncyeQy = (this.list[19].yearFxdxTotal  + this.list[19].yearXdxTotal ).toFixed(2)*1
      this.list[20].ncyeQy = (this.list[20].yearFxdxTotal  + this.list[20].yearXdxTotal ).toFixed(2)*1
      
    },
    getList(period){
      if (this.typeName == '历史数据') {
        let periodZC
        if (period) {
          periodZC = period
        } else {
          periodZC = this.$store.getters['user/comInfo'].period;
        }
        
        hisLrbInfo({ period: periodZC }).then(res => {
          if (res.data.data.info) {
            this.list = res.data.data.info
            this.changeList()
            // this.type = res.data.data.type
          }
        })
      }else{
        let param = {
          tableName:'kj_lrb',
          period:this.startAccountPeriod,
          comId:this.comId
        }
        if(this.source){
          param.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info && res.data.data.info.length !=0){
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.changeList()
          }
        })
      }
      
    },
    saveLrb(){
      let param = {
        tableName:'kj_lrb',
        items:this.list,
        period:this.startAccountPeriod,
        comId:this.comId
      }
      gsEdit(param).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.getList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  background-color: #fff; 
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #000;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 6px;
    font-size: 13px;
    padding-right:20px;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.bg_color{
  background-color: #d6e2ea;
}
</style>

<template>
  <!-- 小企业 利润表-->
  <div class="swbb_table_width">
    <div class="big_box4">
      <div class="top_title3">
        <h4>{{title}}</h4>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>{{durationOfTaxTime}}</span></h6>
          <h6 class="center" v-if="title == '利润表(季报)'"><span>{{periodLrb}}</span></h6>
          <h6 class="center" v-else-if="title == '利润表(月报)'"><span>{{periodLrb2}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:45%;">项目</td>
          <td class="center" style="width:5%;">行次</td>
          <td class="center" style="width:25%;">本年累计金额</td>
          <td class="center" style="width:25%;" v-if="title == '利润表(季报)'">本期金额</td>
          <td class="center" style="width:25%;" v-else-if="title == '利润表(月报)'">本月金额</td>
        </tr>
      </table>
      <el-scrollbar :style="{height:contentStyleObj}">
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight bg_color" style="width:45%;">一、营业收入</td>
            <td class="center bg_color" style="width:5%;">1</td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="list[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="list[0].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color ">减：营业成本</td>
            <td class="center bg_color">2</td>
            <qzf-td :disabled="false" v-model:amount="list[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[1].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_k">税金及附加<el-button size="small" type="text" @click="qslyShow('税金及附加',list[2].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">3</td>
            <qzf-td :disabled="false" v-model:amount="list[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[2].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_t">其中：消费税<el-button size="small" type="text" @click="qslyShow('消费税',list[3].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">4</td>
            <qzf-td :disabled="false" v-model:amount="list[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[3].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">营业税<el-button size="small" type="text" @click="qslyShow('营业税',list[4].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">5</td>
            <qzf-td :disabled="false" v-model:amount="list[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[4].periodTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="bg_color left_s">城市维护建设税<el-button size="small" type="text" @click="qslyShow('城市维护建设税',list[5].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">6</td>
            <qzf-td :disabled="false" v-model:amount="list[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[5].periodTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="bg_color left_s">资源税<el-button size="small" type="text" @click="qslyShow('资源税',list[6].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">7</td>
            <qzf-td :disabled="false" v-model:amount="list[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[6].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">土地增值税<el-button size="small" type="text" @click="qslyShow('土地增值税',list[7].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">8</td>
            <qzf-td :disabled="false" v-model:amount="list[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[7].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">城镇土地使用税、房产税、车船税、印花税<el-button size="small" type="text" @click="qslyShow('城镇土地使用税、房产税、车船税、印花税',list[8].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">9</td>
            <qzf-td :disabled="false" v-model:amount="list[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[8].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">教育附加、矿产资源、排污费<el-button size="small" type="text" @click="qslyShow('教育附加、矿产资源、排污费',list[9].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">10</td>
            <qzf-td :disabled="false" v-model:amount="list[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[9].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_k">销售费用</td>
            <td class="center bg_color">11</td>
            <qzf-td :disabled="false" v-model:amount="list[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[10].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_t">其中：商品维修费<el-button size="small" type="text" @click="qslyShow('商品维修费',list[11].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">12</td>
            <qzf-td :disabled="false" v-model:amount="list[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[11].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">广告费和业务宣传费<el-button size="small" type="text" @click="qslyShow('广告费和业务宣传费',list[12].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">13</td>
            <qzf-td :disabled="false" v-model:amount="list[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[12].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_k">管理费用</td>
            <td class="center bg_color">14</td>
            <qzf-td :disabled="false" v-model:amount="list[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[13].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_t">其中：开办费<el-button size="small" type="text" @click="qslyShow('开办费',list[14].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">15</td>
            <qzf-td :disabled="false" v-model:amount="list[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[14].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">业务招待费<el-button size="small" type="text" @click="qslyShow('业务招待费',list[15].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">16</td>
            <qzf-td :disabled="false" v-model:amount="list[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[15].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">研究费用<el-button size="small" type="text" @click="qslyShow('研究费用',list[16].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">17</td>
            <qzf-td :disabled="false" v-model:amount="list[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[16].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_k">财务费用</td>
            <td class="center bg_color">18</td>
            <qzf-td :disabled="false" v-model:amount="list[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[17].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_t">其中：利息费用(收入以-号填列)<el-button size="small" type="text" @click="qslyShow('利息费用',list[18].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">19</td>
            <qzf-td :disabled="false" v-model:amount="list[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[18].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">加：投资收益<el-button size="small" type="text" @click="qslyShow('投资收益',list[19].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">20</td>
            <qzf-td :disabled="false" v-model:amount="list[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[19].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">二、营业利润</td>
            <td class="center bg_color">21</td>
            <qzf-td :disabled="true" v-model:amount="list[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[20].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">加：营业外收入</td>
            <td class="center bg_color">22</td>
            <qzf-td :disabled="false" v-model:amount="list[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[21].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_t">其中：政府补助<el-button size="small" type="text" @click="qslyShow('政府补助',list[22].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">23</td>
            <qzf-td :disabled="false" v-model:amount="list[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[22].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">减：营业外支出</td>
            <td class="center bg_color">24</td>
            <qzf-td :disabled="false" v-model:amount="list[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[23].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_t">其中：坏账损失<el-button size="small" type="text" @click="qslyShow('坏账损失',list[24].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">25</td>
            <qzf-td :disabled="false" v-model:amount="list[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[24].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">无法收回的长期债券投资损失<el-button size="small" type="text" @click="qslyShow('无法收回的长期债券投资损失',list[25].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">26</td>
            <qzf-td :disabled="false" v-model:amount="list[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[25].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">无法收回的长期股权投资损失<el-button size="small" type="text" @click="qslyShow('无法收回的长期股权投资损失',list[26].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">27</td>
            <qzf-td :disabled="false" v-model:amount="list[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[26].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">自然灾害等不可抗力因素造成的损失<el-button size="small" type="text" @click="qslyShow('自然灾害等不可抗力因素造成的损失',list[27].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">28</td>
            <qzf-td :disabled="false" v-model:amount="list[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[27].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color left_s">税收滞纳金<el-button size="small" type="text" @click="qslyShow('税收滞纳金',list[28].type)" class="qsly" v-if="title == '利润表(季报)' && !this.startAccountPeriod">设置取数来源</el-button></td>
            <td class="center bg_color">29</td>
            <qzf-td :disabled="false" v-model:amount="list[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[28].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">三、利润总额</td>
            <td class="center bg_color">30</td>
            <qzf-td :disabled="true" v-model:amount="list[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[29].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="bg_color">减：所得税费用</td>
            <td class="center bg_color">31</td>
            <qzf-td :disabled="false" v-model:amount="list[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="list[30].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">四、净利润</td>
            <td class="center bg_color">32</td>
            <qzf-td :disabled="true" v-model:amount="list[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="list[31].periodTotal" @change="changeList()"></qzf-td>
          </tr>
        </table>
        </el-scrollbar>
    </div>
    <div class="save_box" v-if="this.typeName != '历史数据' && $buttonStatus('kjbb_bc') && (this.startAccountPeriod == ''?( this.currentPeriod!= ''?true:(this.currentPeriod == this.startAccountPeriod?true:false)):(currentPeriod != ''?(this.currentPeriod == this.startAccountPeriod?true:false):(this.currentPeriod == ''?true:false)))">
      <el-button @click="saveLrb" type="primary" size="small"  v-if="this.startAccountPeriod">
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 13px;"></i>
        <span  > 保存</span>
      </el-button>
      <qzf-button @success="saveLrb" type="primary" size="small"  v-else>
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 13px;"></i>
        <span  > 保存</span>
      </qzf-button>
    </div>
    <!-- 新增弹窗取数来源 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogConversion" title="新增取数来源"  class="button_bg">
      <el-form ref="dataForm" label-position="left" label-width="60px" class="clearfix" style="overflow:hidden" size="small">
        <div v-if="this.qslxType == '税金及附加'">
          <el-form-item label="科目：">
            <el-select v-model="qslySubject" placeholder="请选择" clearable>
              <el-option label="2221 应交税费" :value="2221"></el-option>
              <el-option label="5403 税金及附加" :value="5403"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-else>
          <el-button style="margin-bottom:10px;" size="small" @click="addUnits" round>新增取数来源</el-button>
          <div style="margin-bottom:10px;" v-for="(item,index) in qslyList" :key="index">
            <span>科目：</span>
            <subject-list v-model:subjectId="item.subjectId"></subject-list>
            <i style="font-size:20px;margin-left:10px;cursor: pointer;" @click="delUnits(index)" class="iconfont icon-shanchu"></i>       
          </div>
        </div>
       
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogConversion = false" size="small">取 消</el-button>
          <el-button type="primary" @click="conversionSave()" size="small">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit ,eaLrbOriginLists ,eaLrbOriginSave} from '@/api/taxCalculation.js'
import { hisLrbInfo,hisLrbMonthInfo } from '@/api/old.js'
export default {
  name: 'lrbTable',
  components: { },
  props: {
    title: String,
    typeName: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      list: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      periodLrb: '',
      periodLrb2: '',
      type:0,
      qslyList: [],
      dialogConversion: false,
      subjectList:[],
      comName:'',
      taxNo:'',
      durationOfTaxTime:'',
      num:0,
      currentPeriod:this.$store.getters["user/comInfo"].period,
      qslySubject:undefined
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(302)
  },
  methods: {
    changeList(){
      //营业利润
      this.list[20].yearTotal = (this.list[0].yearTotal  - 
                                      this.list[1].yearTotal  -
                                      this.list[2].yearTotal  -  
                                      this.list[10].yearTotal  - 
                                      this.list[13].yearTotal  - 
                                      this.list[17].yearTotal  + 
                                      this.list[19].yearTotal ).toFixed(2)*1
      this.list[20].periodTotal = (this.list[0].periodTotal  - 
                                        this.list[1].periodTotal  - 
                                        this.list[2].periodTotal  - 
                                        this.list[10].periodTotal  - 
                                        this.list[13].periodTotal  - 
                                        this.list[17].periodTotal  + 
                                        this.list[19].periodTotal  ).toFixed(2)*1
       //利润总额
      this.list[29].yearTotal = (this.list[20].yearTotal +
                                      this.list[21].yearTotal -
                                      this.list[23].yearTotal ).toFixed(2)*1
      this.list[29].periodTotal = (this.list[20].periodTotal +
                                        this.list[21].periodTotal -
                                        this.list[23].periodTotal ).toFixed(2)*1
      // 净利润
      this.list[31].yearTotal = ( this.list[29].yearTotal - 
                                      this.list[30].yearTotal ).toFixed(2)*1
      this.list[31].periodTotal = ( this.list[29].periodTotal - 
                                        this.list[30].periodTotal ).toFixed(2)*1
    },
    getList(period){
      if(this.title == '利润表(季报)'){
        if(this.typeName == '历史数据'){
          let periodZC
          if(period){
            periodZC = period
          } else {
            periodZC = this.$store.getters['user/comInfo'].period;
          }
          hisLrbInfo({period:periodZC}).then(res=>{
            if(res.data.data.info){
              this.list = res.data.data.info
              // this.type = res.data.data.type
            }
          })
        } else if(this.typeName == '会计报表') {
          if(this.startAccountPeriod){
            let param = {
              tableName: 'kj_lrb',
              period:this.startAccountPeriod,
              comId:this.comId
            }
            if(this.source){
              param.method = this.$store.getters["commons/params"].initMethod
            }
            gsInfo(param).then(res=>{
              this.durationOfTaxTime = this.startAccountPeriod
              this.comName = res.data.data.name
              this.taxNo = res.data.data.taxNo
              if(res.data.data.info && res.data.data.info.length == 32){
                this.list = res.data.data.info
              }else{
                this.$notify({
                  title: '警告',
                  message: '数据校验有误，检测到可能更改过会计准则，请查验初期会计准则是否一致',
                  duration:0,
                  type: 'warning',
                });
              }
            })
          }else{
            gsInfo({tableName: 'kj_lrb'}).then(res=>{
              if(res.data.data.info){
                this.list = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }
        }  
      }else if(this.title == '利润表(月报)'){
        if(this.typeName == '历史数据'){
          let periodZC
          if(period){
            periodZC = period
          } else {
            periodZC = this.$store.getters['user/comInfo'].period;
          }
          hisLrbMonthInfo({period:periodZC}).then(res=>{
            if(res.data.data.info){
              this.list = res.data.data.info
              // this.type = res.data.data.type
            }
          })
        } else if(this.typeName == '会计报表') {
          if(this.startAccountPeriod){
            let param = {
              tableName: 'kj_lrb',
              period:this.startAccountPeriod,
              comId:this.comId,
              itemName:'month',
            }
            gsInfo(param).then(res=>{
              if(res.data.data.info){
                this.list = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }else{
            gsInfo({tableName: 'kj_lrb',itemName:'month'}).then(res=>{
              if(res.data.data.info){
                this.list = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }
        }
      }
    },
    saveLrb(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'kj_lrb',
          items:this.list,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        let paramMon = {
          tableName:'kj_lrb',
          items:this.list,
          itemName:'month',
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        if(this.title == '利润表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '利润表(月报)'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }else{
        let param = {
          tableName:'kj_lrb',
          items:this.list
        }
        let paramMon = {
          tableName:'kj_lrb',
          items:this.list,
          itemName:'month'
        }
        if(this.title == '利润表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '利润表(月报)'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }
    },
    // 取数来源
    qslyShow(type,num){
      if(this.$checkSettleStatus()) return
      this.qslyList = [] //放到接口
      this.qslxType = type
      this.num = num
      eaLrbOriginLists({name:type}).then(res=>{
        this.qslyList = res.data.data.list
        this.dialogConversion = true
        if(type == '税金及附加'){
          this.qslySubject = res.data.data.list && res.data.data.list.length>0?res.data.data.list[0].subjectId:undefined
        }
      })
    },
    // 删除取数来源
    delUnits(index){
      this.qslyList.splice(index, 1)
    },
    addUnits(){
      this.qslyList.push({
        subjectId: '',
      })
    },
    // 保存取数来源
    conversionSave(){
      let param
      if(this.qslxType == '税金及附加'){
        param = {
          name:this.qslxType,
          num:Number(this.num),
          items:this.qslySubject ? [{subjectId:this.qslySubject}] : []
        } 
      }else{
        param = {name:this.qslxType,num:Number(this.num),items:this.qslyList}
      }
      eaLrbOriginSave(param).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage('保存成功')
          this.dialogConversion = false
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  background-color: #fff;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #000;
    font-size: 13px;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_t{
  text-indent: 3em;
}
.left_s{
  text-indent: 6em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  float: right;
  cursor: pointer;
}
.bg_color{
  background-color: #d6e2ea;
}
</style>

<template>
  <!-- 企业会计制度 利润表-->
  <div class="swbb_table_width">
    <div class="big_box4">
      <div class="top_title3">
        <h4 v-if="title == '利润表(季报)'">利润表(季报)（适用已执行新金融准则的一般企业）</h4>
        <h4 v-else-if="title == '利润表(月报)'">利润表(月报)（适用已执行新金融准则的一般企业）</h4>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>{{durationOfTaxTime}}</span></h6>
          <h6 class="center" v-if="title == '利润表(季报)'"><span>{{periodLrb}}</span></h6>
          <h6 class="center" v-else-if="title == '利润表(月报)'"><span>{{periodLrb2}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content content_head" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:45%;">项目</td>
          <td class="center" style="width:5%;">行次</td>
          <td class="center" style="width:25%;">本年累计金额</td>
          <td class="center" style="width:25%;" v-if="title == '利润表(季报)'">本期金额</td>
          <td class="center" style="width:25%;" v-else-if="title == '利润表(月报)'">本月金额</td>
        </tr>
      </table>
      <!-- <div class="fixed_box_table" :style="{height:contentStyleObj}"> -->
        <el-scrollbar :style="{height:contentStyleObj}">
        <table class="content content_body" cellspacing="0">
          <tr>
            <td class="weight bg_color" style="width:45%;">一、营业收入</td>
            <td class="center bg_color" style="width:5%;">1</td>
            <!-- <td style="width:24%;"><input type="text" v-model="attachArr[0].yearTotal"></td>
            <td style="width:24%;"><input type="text" v-model="attachArr[0].periodTotal"></td> -->
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[0].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td style="width:25%;" :disabled="false" v-model:amount="attachArr[0].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：营业成本</td>
            <td class="center bg_color">2</td>
            <!-- <td><input type="text" v-model:amount="attachArr[1].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[1].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[1].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[1].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">税金及附加</td>
            <td class="center bg_color">3</td>
            <!-- <td><input type="text" v-model:amount="attachArr[2].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[2].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[2].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[2].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">销售费用</td>
            <td class="center bg_color">4</td>
            <!-- <td><input type="text" v-model:amount="attachArr[3].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[3].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[3].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[3].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">管理费用</td>
            <td class="center bg_color">5</td>
            <!-- <td><input type="text" v-model:amount="attachArr[4].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[4].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[4].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[4].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">研发费用</td>
            <td class="center bg_color">6</td>
            <!-- <td><input type="text" v-model:amount="attachArr[5].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[5].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[5].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[5].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_b bg_color">财务费用</td>
            <td class="center bg_color">7</td>
            <!-- <td><input type="text" v-model:amount="attachArr[6].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[6].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[6].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[6].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">其中：利息费用</td>
            <td class="center bg_color">8</td>
            <!-- <td><input type="text" v-model:amount="attachArr[7].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[7].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[7].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[7].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_d bg_color">利息收入</td>
            <td class="center bg_color">9</td>
            <!-- <td><input type="text" v-model:amount="attachArr[8].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[8].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[8].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[8].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">加：其他收益</td>
            <td class="center bg_color">10</td>
            <!-- <td><input type="text" v-model:amount="attachArr[9].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[9].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[9].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[9].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <!-- <tr>
              -->
          <tr>
            <td class="left_b bg_color">投资收益（损失以“﹣”号填列）</td>
            <td class="center bg_color">11</td>
            <!-- <td><input type="text" v-model:amount="attachArr[10].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[10].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[10].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[10].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">其中：对联营企业和合营企业的投资收益</td>
            <td class="center bg_color">12</td>
            <!-- <td><input type="text" v-model:amount="attachArr[11].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[11].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[11].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[11].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">以摊余成本计量的金融资产终止确认收益(损失以“﹣”号填列)</td>
            <td class="center bg_color">13</td>
            <!-- <td><input type="text" v-model:amount="attachArr[12].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[12].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[12].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[12].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">净敞口套期收益(损失以“﹣”号填列)</td>
            <td class="center bg_color">14</td>
            <!-- <td><input type="text" v-model:amount="attachArr[13].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[13].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[13].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[13].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">公允价值变动收益（损失以“﹣”号填列）</td>
            <td class="center bg_color">15</td>
            <!-- <td><input type="text" v-model:amount="attachArr[14].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[14].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[14].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[14].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">信用减值损失（损失以“﹣”号填列）</td>
            <td class="center bg_color">16</td>
            <!-- <td><input type="text" v-model:amount="attachArr[15].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[15].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[15].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[15].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">资产减值损失（损失以“﹣”号填列）</td>
            <td class="center bg_color">17</td>
            <!-- <td><input type="text" v-model:amount="attachArr[16].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[16].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[16].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[16].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">资产处置收益（损失以“﹣”号填列）</td>
            <td class="center bg_color">18</td>
            <!-- <td><input type="text" v-model:amount="attachArr[17].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[17].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[17].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[17].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">二、营业利润（亏损以“﹣”号填列）</td>
            <td class="center bg_color">19</td>
            <!-- <td><input type="text" v-model:amount="attachArr[18].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[18].periodTotal"></td> -->
            <qzf-td :disabled="true" v-model:amount="attachArr[18].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="attachArr[18].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">加：营业外收入</td>
            <td class="center bg_color">20</td>
            <!-- <td><input type="text" v-model:amount="attachArr[19].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[19].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[19].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[19].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：营业外支出</td>
            <td class="center bg_color">21</td>
            <!-- <td><input type="text" v-model:amount="attachArr[20].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[20].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[20].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[20].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">三、利润总额（亏损总额以“﹣”号填列）</td>
            <td class="center bg_color">22</td>
            <!-- <td><input type="text" v-model:amount="attachArr[21].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[21].periodTotal"></td> -->
            <qzf-td :disabled="true" v-model:amount="attachArr[21].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="attachArr[21].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">减：所得税费用</td>
            <td class="center bg_color">23</td>
            <!-- <td><input type="text" v-model:amount="attachArr[22].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[22].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[22].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[22].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">四、净利润（净亏损以“﹣”号填列）</td>
            <td class="center bg_color">24</td>
            <!-- <td><input type="text" v-model:amount="attachArr[23].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[23].periodTotal"></td> -->
            <qzf-td :disabled="true" v-model:amount="attachArr[23].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="attachArr[23].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">（一）持续经营净利润（净亏损以“﹣”号填列）</td>
            <td class="center bg_color">25</td>
            <!-- <td><input type="text" v-model:amount="attachArr[24].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[24].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[24].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[24].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">（二）终止经营净利润（净亏损以“﹣”号填列）</td>
            <td class="center bg_color">26</td>
            <!-- <td><input type="text" v-model:amount="attachArr[25].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[25].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[25].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[25].periodTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="weight bg_color">五、其他综合收益的税后净额</td>
            <td class="center bg_color">27</td>
            <!-- <td><input type="text" v-model:amount="attachArr[26].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[26].periodTotal"></td> -->
            <qzf-td :disabled="true" v-model:amount="attachArr[26].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="attachArr[26].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">（一）不能重分类进损益的其他综合收益</td>
            <td class="center bg_color">28</td>
            <!-- <td><input type="text" v-model:amount="attachArr[27].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[27].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[27].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[27].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">1.重新计量设定受益计划变动额</td>
            <td class="center bg_color">29</td>
            <!-- <td><input type="text" v-model:amount="attachArr[28].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[28].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[28].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[28].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">2.权益法下不能转损益的其他利益综合收益</td>
            <td class="center bg_color">30</td>
            <!-- <td><input type="text" v-model:amount="attachArr[29].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[29].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[29].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[29].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">3.其他权益工具投资公允价值变动</td>
            <td class="center bg_color">31</td>
            <!-- <td><input type="text" v-model:amount="attachArr[30].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[30].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[30].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[30].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">4．企业自身信用风险公允价值变动</td>
            <td class="center bg_color">32</td>
            <!-- <td><input type="text" v-model:amount="attachArr[31].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[31].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[31].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[31].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">（二）将重分类进损益的其他综合收益</td>
            <td class="center bg_color">33</td>
            <!-- <td><input type="text" v-model:amount="attachArr[32].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[32].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[32].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[32].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">1.权益法下可转损益的其他综合收益</td>
            <td class="center bg_color">34</td>
            <!-- <td><input type="text" v-model:amount="attachArr[33].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[33].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[33].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[33].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">2.其他债权投资公允价值变动</td>
            <td class="center bg_color">35</td>
            <!-- <td><input type="text" v-model:amount="attachArr[34].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[34].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[34].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[34].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">3.金融资产重分类计入其他综合收益的金额</td>
            <td class="center bg_color">36</td>
            <!-- <td><input type="text" v-model:amount="attachArr[35].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[35].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[35].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[35].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">4.其他债权投资信用减值准备</td>
            <td class="center bg_color">37</td>
            <!-- <td><input type="text" v-model:amount="attachArr[36].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[36].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[36].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[36].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">5.现金流量套期储备</td>
            <td class="center bg_color">38</td>
            <!-- <td><input type="text" v-model:amount="attachArr[37].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[37].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[37].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[37].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_x bg_color">6.外币财务报表折算差额</td>
            <td class="center bg_color">39</td>
            <!-- <td><input type="text" v-model:amount="attachArr[38].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[38].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[38].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[38].periodTotal" @change="changeList()"></qzf-td>
          </tr>

          <tr>
            <td class="weight bg_color">六、综合收益总额</td>
            <td class="center bg_color">40</td>
            <!-- <td><input type="text" v-model:amount="attachArr[39].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[39].periodTotal"></td> -->
            <qzf-td :disabled="true" v-model:amount="attachArr[39].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="true" v-model:amount="attachArr[39].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="weight bg_color">七、每股收益</td>
            <td class="center bg_color"></td>
            <td></td>
            <td></td>
          </tr>
          
          <tr>
            <td class="left_k bg_color">（一）基本每股收益</td>
            <td class="center bg_color">41</td>
            <!-- <td><input type="text" v-model:amount="attachArr[40].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[40].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[40].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[40].periodTotal" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="left_k bg_color">（二）稀释每股收益</td>
            <td class="center bg_color">42</td>
            <!-- <td><input type="text" v-model:amount="attachArr[41].yearTotal"></td>
            <td><input type="text" v-model:amount="attachArr[41].periodTotal"></td> -->
            <qzf-td :disabled="false" v-model:amount="attachArr[41].yearTotal" @change="changeList()"></qzf-td>
            <qzf-td :disabled="false" v-model:amount="attachArr[41].periodTotal" @change="changeList()"></qzf-td>
          </tr>

        </table>
       </el-scrollbar>
      <!-- </div> -->
    </div>
    <div class="save_box" v-if="this.typeName != '历史数据'&& $buttonStatus('kjbb_bc')&& (this.startAccountPeriod == ''?( this.currentPeriod!= ''?true:(this.currentPeriod == this.startAccountPeriod?true:false)):(currentPeriod != ''?(this.currentPeriod == this.startAccountPeriod?true:false):(this.currentPeriod == ''?true:false)))">
      <el-button @click="saveLrb" type="primary" size="small"  v-if="this.startAccountPeriod">
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 13px;"></i>
        <span  > 保存</span>
      </el-button>
      <qzf-button @success="saveLrb" type="primary" size="small"  v-else>
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 13px;"></i>
        <span  > 保存</span>
      </qzf-button>
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
import { hisLrbInfo,hisLrbMonthInfo } from '@/api/old.js'

export default {
  name: 'lrbTable4',
  components: { },
  props: {
    title: String,
    typeName: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      attachArr: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      periodLrb: '',
      periodLrb2: '',
      type:0,
      comName:'',
      taxNo:'',
      durationOfTaxTime:'',
      currentPeriod:this.$store.getters["user/comInfo"].period,
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(304)
  },
  methods: {
    changeList(){
      //营业利润
      this.attachArr[18].yearTotal = (this.attachArr[0].yearTotal  - 
                                      this.attachArr[1].yearTotal  -
                                      this.attachArr[2].yearTotal  -  
                                      this.attachArr[3].yearTotal  - 
                                      this.attachArr[4].yearTotal  - 
                                      this.attachArr[5].yearTotal  - 
                                      this.attachArr[6].yearTotal  + 
                                      this.attachArr[9].yearTotal  +
                                      this.attachArr[10].yearTotal +
                                      this.attachArr[12].yearTotal +
                                      this.attachArr[13].yearTotal +
                                      this.attachArr[14].yearTotal +
                                      this.attachArr[15].yearTotal +
                                      this.attachArr[16].yearTotal +
                                      this.attachArr[17].yearTotal ).toFixed(2)*1
      this.attachArr[18].periodTotal = (this.attachArr[0].periodTotal  - 
                                        this.attachArr[1].periodTotal  - 
                                        this.attachArr[2].periodTotal  - 
                                        this.attachArr[3].periodTotal  - 
                                        this.attachArr[4].periodTotal  - 
                                        this.attachArr[5].periodTotal  - 
                                        this.attachArr[6].periodTotal  + 
                                        this.attachArr[9].periodTotal  +
                                        this.attachArr[10].periodTotal +
                                        this.attachArr[12].periodTotal +
                                        this.attachArr[13].periodTotal +
                                        this.attachArr[14].periodTotal +
                                        this.attachArr[15].periodTotal +
                                        this.attachArr[16].periodTotal +
                                        this.attachArr[17].periodTotal ).toFixed(2)*1
      //利润总额
      this.attachArr[21].yearTotal = (this.attachArr[18].yearTotal +
                                      this.attachArr[19].yearTotal -
                                      this.attachArr[20].yearTotal ).toFixed(2)*1
      this.attachArr[21].periodTotal = (this.attachArr[18].periodTotal +
                                        this.attachArr[19].periodTotal -
                                        this.attachArr[20].periodTotal ).toFixed(2)*1
       //净利润
      this.attachArr[23].yearTotal = (this.attachArr[21].yearTotal -
                                      this.attachArr[22].yearTotal ).toFixed(2)*1
      this.attachArr[23].periodTotal = (this.attachArr[21].periodTotal -
                                        this.attachArr[22].periodTotal ).toFixed(2)*1 
      //五、其他综合收益的税后净额 
      this.attachArr[26].yearTotal = (this.attachArr[27].yearTotal +
                                      this.attachArr[32].yearTotal ).toFixed(2)*1
      this.attachArr[26].periodTotal = (this.attachArr[27].periodTotal +
                                        this.attachArr[32].periodTotal ).toFixed(2)*1
      // 综合收益总额
      this.attachArr[39].yearTotal = (this.attachArr[23].yearTotal +
                                      this.attachArr[26].yearTotal ).toFixed(2)*1
      this.attachArr[39].periodTotal = (this.attachArr[23].periodTotal +
                                        this.attachArr[26].periodTotal ).toFixed(2)*1
    },
    getList(period){
      if(this.title == '利润表(季报)'){
        if(this.typeName == '历史数据'){
          let periodZC
          if(period){
            periodZC = period
          } else {
            periodZC = this.$store.getters['user/comInfo'].period;
          }
          hisLrbInfo({period:periodZC}).then(res=>{
            if(res.data.data.info){
              this.attachArr = res.data.data.info
              this.comName = this.$store.getters['user/comInfo'].comName;
              this.taxNo = this.$store.getters['user/comInfo'].taxNo;
              // this.type = res.data.data.type
            }
          })
        } else if(this.typeName == '会计报表') {
          if(this.startAccountPeriod){
            let param = {
              tableName: 'kj_lrb',
              period:this.startAccountPeriod,
              comId:this.comId
            }
            if(this.source){
              param.method = this.$store.getters["commons/params"].initMethod
            }
            gsInfo(param).then(res=>{
              this.durationOfTaxTime = this.startAccountPeriod
              this.comName = res.data.data.name
              this.taxNo = res.data.data.taxNo
              if(res.data.data.info && res.data.data.info.length == 42){
                this.attachArr = res.data.data.info
              }else{
                this.$notify({
                  title: '警告',
                  message: '数据校验有误，检测到可能更改过会计准则，请查验初期会计准则是否一致',
                  duration:0,
                  type: 'warning',
                });
              }
            })
          }else{
            gsInfo({tableName: 'kj_lrb'}).then(res=>{
              if(res.data.data.info){
                this.attachArr = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }
        }
      }else if(this.title == '利润表(月报)'){
        if(this.typeName == '历史数据'){
          let periodZC
          if(period){
            periodZC = period
          } else {
            periodZC = this.$store.getters['user/comInfo'].period;
          }
          hisLrbMonthInfo({period:periodZC}).then(res=>{
            if(res.data.data.info){
              this.attachArr = res.data.data.info
              this.comName = this.$store.getters['user/comInfo'].comName;
              this.taxNo = this.$store.getters['user/comInfo'].taxNo;
              // this.type = res.data.data.type
            }
          })
        } else if(this.typeName == '会计报表') {
          if(this.startAccountPeriod){
            let param = {
              tableName: 'kj_lrb',
              period:this.startAccountPeriod,
              comId:this.comId,
              itemName:'month',
            }
            gsInfo(param).then(res=>{
              if(res.data.data.info){
                this.attachArr = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }else{
            gsInfo({tableName: 'kj_lrb',itemName:'month'}).then(res=>{
              if(res.data.data.info){
                this.attachArr = res.data.data.info
                this.comName = res.data.data.name
                this.taxNo = res.data.data.taxNo
                this.durationOfTaxTime = res.data.data.time
              }
            })
          }
        }
      }
    },
    saveLrb(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'kj_lrb',
          items:this.attachArr,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        let paramMon = {
          tableName:'kj_lrb',
          items:this.attachArr,
          itemName:'month',
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        if(this.title == '利润表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '利润表(月报)'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }else{
        let param = {
          tableName:'kj_lrb',
          items:this.attachArr
        }
        let paramMon = {
          tableName:'kj_lrb',
          items:this.attachArr,
          itemName:'month'
        }
        if(this.title == '利润表(季报)'){
          gsEdit(param).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }else if(this.title == '利润表(月报)'){
          gsEdit(paramMon).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("保存成功")
              this.getList()
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  background-color: #fff;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #000;
    font-size: 13px;
  }
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  cursor: pointer;
}
.bg_color{
  background-color: #d6e2ea;
}
</style>

<template>
  <div class="swbb_table_width">
    <div class="top_title3">
      <h4>成员权益变动表</h4>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <el-scrollbar :style="{height:contentStyleObj}">
      <table class="content" cellspacing="0">
        <tr class="top_bg">
          <td class="center weight" style="width:10%;" >项目</td>
          <td class="center weight" style="width:16%;" colspan="2">股金</td>
          <td class="center weight" style="width:16%;" colspan="2">专项基金</td>
          <td class="center weight" style="width:16%;" colspan="2">资本公积</td>
          <td class="center weight" style="width:16%;" colspan="2">盈余公积</td>
          <td class="center weight" style="width:16%;" colspan="2">未分配盈余</td>
          <td class="center weight" style="width:10%;">合计</td>
        </tr>
        <tr>
          <td style="width:10%;" class="bg_color">年初余额</td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[0].gj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[0].zxjj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[0].zbgj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[0].yygj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[0].wfpyy" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[0].total" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">本年增加数</td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[1].gj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[1].zxjj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[1].zbgj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[1].yygj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[1].wfpyy" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[1].total" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color">其中：</td>
          <td></td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td style="width:8%" class="bg_color">资本公积转增</td>
          <qzf-str-td :disabled="false" v-model:amount="list[3].gj" @change="changeList()"></qzf-str-td>
          <td style="width:11%" class="bg_color">接受国家财政直接补助</td>
          <qzf-str-td :disabled="false" v-model:amount="list[3].zxjj" @change="changeList()"></qzf-str-td>
          <td style="width:8%" class="bg_color">股金溢价</td>
          <qzf-str-td :disabled="false" v-model:amount="list[3].zbgj" @change="changeList()"></qzf-str-td>
          <td style="width:8%" class="bg_color">从盈余中提取</td>
          <qzf-str-td :disabled="false" v-model:amount="list[3].yygj" @change="changeList()"></qzf-str-td>
          <td style="width:8%" class="bg_color">本年盈余</td>
          <qzf-str-td :disabled="false" v-model:amount="list[3].wfpyy" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[3].total" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td class="bg_color">盈余公积转增</td>
          <qzf-str-td :disabled="false" v-model:amount="list[4].gj" @change="changeList()"></qzf-str-td>
          <td class="bg_color">接受他人捐赠形成</td>
          <qzf-str-td :disabled="false" v-model:amount="list[4].zxjj" @change="changeList()"></qzf-str-td>
          <td class="bg_color">资产评估增值</td>
          <qzf-str-td :disabled="false" v-model:amount="list[4].zbgj" @change="changeList()"></qzf-str-td>
          <td colspan="2" class="bg_color"></td>
          <td style="width:8%" class="bg_color">其他转入</td>
          <qzf-str-td :disabled="false" v-model:amount="list[4].wfpyy" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[4].total" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td class="bg_color">成员增加出资</td>
          <qzf-str-td :disabled="false" v-model:amount="list[5].gj" @change="changeList()"></qzf-str-td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[5].total" @change="changeList()"></qzf-str-td>
        </tr>

        <tr>
          <td class="bg_color">本年减少数</td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[6].gj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[6].zxjj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[6].zbgj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[6].yygj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[6].wfpyy" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[6].total" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color">其中：</td>
          <td colspan="2" class="bg_color"></td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td class="bg_color">成员减少出资</td>
          <qzf-str-td :disabled="false" v-model:amount="list[8].gj" @change="changeList()"></qzf-str-td>
          <td colspan="2" class="bg_color"></td>
          <td class="bg_color">转为成员出资</td>
          <qzf-str-td :disabled="false" v-model:amount="list[8].zbgj" @change="changeList()"></qzf-str-td>
          <td class="bg_color">转为成员出资</td>
          <qzf-str-td :disabled="false" v-model:amount="list[8].yygj" @change="changeList()"></qzf-str-td>
          <td class="bg_color">提取盈余公积</td>
          <qzf-str-td :disabled="false" v-model:amount="list[8].wfpyy" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[8].total" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td class="bg_color">弥补亏损</td>
          <qzf-str-td :disabled="false" v-model:amount="list[9].zbgj" @change="changeList()"></qzf-str-td>
          <td colspan="2" class="bg_color"></td>
          <td class="bg_color">盈余返还</td>
          <qzf-str-td :disabled="false" v-model:amount="list[9].wfpyy" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[9].total" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td class="bg_color">剩余盈余分配</td>
          <qzf-str-td :disabled="false" v-model:amount="list[10].wfpyy" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[10].total" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td colspan="2" class="bg_color"></td>
          <td class="bg_color">转为成员出资</td>
          <qzf-str-td :disabled="false" v-model:amount="list[11].wfpyy" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[11].total" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">年末余额</td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[12].gj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[12].zxjj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[12].zbgj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[12].yygj" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[12].wfpyy" @change="changeList()"></qzf-str-td>
          <qzf-str-td colspan="2" :disabled="false" v-model:amount="list[12].total" @change="changeList()"></qzf-str-td>

        </tr>
      </table>
    </el-scrollbar>
    <div class="save_box" v-if="this.typeName != '历史数据'&& $buttonStatus('kjbb_bc')">
      <el-button type="primary" @click="saveValues()" size="small"><i class="iconfont icon-baocun"></i>保存</el-button>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
import { hisLrbInfo } from '@/api/old.js'
export default {
  name: 'culturalMain',
  props: {
    typeName: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      durationOfTaxTime: '',
      activeName: '1',
      name: '1',
      listQuery: {},
      list: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},],
      attachArr: [],
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      contentStyleObj:{}, //高度变化
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(275)
  },
  methods: {
    // 公式
    changeList(){
      // this.list.bqybtfe = (this.list.yjfeBys*0.5 - this.list.qzbqyjfeBys).toFixed(2)*1
    },
    getList(period){
      if(this.typeName == '历史数据'){
        let periodZC
        if(period){
          periodZC = period
        } else {
          periodZC = this.$store.getters['user/comInfo'].period;
        }
        hisLrbInfo({period:periodZC}).then(res=>{
          if(res.data.data.info ){
            this.list = res.data.data.info
            // this.type = res.data.data.type
          }
        })
      }else{
        if(this.startAccountPeriod){
        let param = {
          tableName: 'kj_lrb',
          itemName:'cy',
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }else{
        gsInfo({tableName: 'kj_lrb',itemName:'cy'}).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
      }
   
    },
    saveValues(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'kj_lrb',
          itemName:'cy',
          items: this.list,
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'kj_lrb',
          itemName:'cy',
          items: this.list
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 13px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  background-color: #fff;
  span{
    line-height: 26px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #000;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 26px;
    padding: 0 6px;
    font-size: 13px;
  }
}
.bg_color{
  background-color: #d6e2ea;
}
</style>